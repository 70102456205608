import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';

export default function TableContrato({ contrato }) {
  const navigate = useNavigate();

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Observação</TableCell>
                <TableCell>Criado</TableCell>
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contrato.projeto && contrato.projeto.map((q) => (
                <TableRow key={q.id}>
                  <TableCell>{q.descricao}</TableCell>
                  <TableCell>{q.observacao}</TableCell>
                  <TableCell>
                    {moment(q.created_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => navigate(
                        `/app/cliente/contrato/${contrato.id}/project/${q.id}`,
                      )}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
TableContrato.propTypes = {
  contrato: PropTypes.shape({
    projeto: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    created_at: PropTypes.string,
  }),
};
