/* eslint-disable react/prop-types */
import React from 'react';
import {
  makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({ questoes }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questoes && questoes.map((q) => (
            <TableRow key={q.questao}>
              <TableCell>{q.questao}</TableCell>
              <TableCell>
                <>
                  {q.questao.feito
                    ? <><p>Sim</p></> : <><p>Não</p></>}
                </>
              </TableCell>
            </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
  );
}
