/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TableQuestion from '../TableQuestion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AccordionStatus({ status, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>

          {status ? (
            <>
              <TableQuestion questions={status} />
            </>
)
          : (<Typography>Não há checklist prennchido</Typography>)}
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
